<template>
  <app-list-view
    server-side
    app="risk"
    model="risktype"
    api-url="risk/risk-type/"
    :title="$t('menu.riskType')"
    :createTo="{ name: 'riskTypeCreate' }"
    :editTo="{ name: 'riskTypeEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'riskTypeList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.riskType'), value: 'name' },
        {
          text: this.$t('fields.riskCategory'),
          value: 'risk_category_id.name'
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'riskType'
    })
  }
}
</script>
